<template>
  <div
    id="top"
    v-bind:class="[isLight ? 'background-light' : 'background-dark', 'contourPage']"
  >
    <VideoBanner :video="'milo.mp4'" :title="'M.I.L.O.'"></VideoBanner>
    <ImageBanner :image="'contour.png'" :title="'Contour'" class="d-none"></ImageBanner>
    <!-- First section  -->
    <div class="page">
      <div v-bind:class="{ background: !isLight }">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Background information
            </h1>

            <Description :description="projectBackground"></Description>
          </div>
          <div class="columnRight">
            <div class="section">
              <Metadata :metadata="pagedata.meta" :showdata="false"></Metadata>
            </div>
            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>

            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- second section-->

    <div :data-aos="fade" class="page">
      <div class="mdAndDown">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              What is M.I.L.O?
            </h1>
            <Description :description="pagedata.info"></Description>
          </div>
          <div class="columnRight">
            <div class="section">
              <v-img eager src="satreal.jpg"></v-img>
            </div>
            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div :data-aos="fade" class="page" style="height: 100% !important">
      <video
        class="body-video"
        style="margin-top: 4%"
        :id="'milo girl'"
        loop="true"
        autoplay="true"
        preload="auto"
        muted="muted"
        width="100%"
        height="auto"
      >
        <source src="milo sonne.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <!-- third section-->
    <div :data-aos="fade" class="page">
      <div class="mdAndDown">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Recognition
            </h1>
            <div
              v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
              v-html="pagedata.recognition"
            ></div>
            <!-- <Description :description="pagedata.recognition"></Description> -->
          </div>
          <div class="columnRight">
            <div class="section">
              <v-img eager src="indigo.png"></v-img>
            </div>
            <!-- <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- third section-->

    <div :data-aos="fade" class="page">
      <div class="mdAndDown">
        <div class="row">
          <div class="columnLeft">
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Technical challenges
            </h1>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              One of the core aspects of Milo is the conversation system which features
              complex non-linear story telling. In order to achieve this level of story
              telling without hardcoding everything, I created a Unity editor tool using
              the xNode plugin.
            </p>
          </div>
          <div class="columnRight">
            <div class="section">
              <Technologies :technologies="pagedata.tech"></Technologies>
            </div>
            <div class="section"></div>
          </div>
        </div>
      </div>
    </div>
    <div :data-aos="fade" class="page">
      <div class="">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Conversation complexity
            </h1>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              The image on the right shows a schemetic representation of a conversation in
              the game. Since the the conversations are not linear it is possible for
              parts of the conversation to be linked to parts either earlier or later in
              the conversation. This added complexity is something we tried to tackle by
              creating a Unity Editor tool.
            </p>
            <!-- <Description :description="projectBackground"></Description> -->
          </div>
          <div class="columnRight">
            <div class="section">
              <v-img eager src="milo-flowchart.png"></v-img>
            </div>
            <div class="section"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- second section-->
    <div :data-aos="fade" class="page">
      <div class="">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Unity Editor tool
            </h1>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              In order to make life easier I created the
              <span style="color: red; font-weight: bold">Conversation Graph Editor</span
              >. In this graph every piece of the conversation is represented as a node.
              There are two types of nodes: Conversation and Answer. The conversations
              parts are used to further the story and present the player a choice. The
              editor requires no coding skills and makes it easy to maintain and reorder a
              complex conversation. <br />
            </p>

            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              When exported, a recursive function is called which loops through the entire
              graph and indexes all of the connections which are stored in the form of a
              <span style="color: red">Scriptable Object</span>. This is a data container
              that you can use to save large amounts of data, independent of class
              instances. This object will then be assigned to a game manager script in the
              editor.
            </p>
            <!-- <Description :description="projectBackground"></Description> -->
          </div>
        </div>
      </div>
    </div>

    <div :data-aos="fade" class="page">
      <div class="">
        <video
          class="body-video"
          style="margin-top: 4%"
          :id="'video_milo_editor'"
          loop="true"
          autoplay="true"
          preload="auto"
          muted="muted"
          width="100%"
          height="auto"
        >
          <source src="milo editor.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <!-- second section-->
    <div :data-aos="fade" class="page">
      <div class="">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              Unity Editor tool
            </h1>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              The editor also supports in-text commands and symbols in order to make the
              conversations more lively and provide better timing.
              <br />
              <br />

              <span style="font-weight: bold">Examples of these commands are: </span>
              <br />
              <span style="color: red">Slash (/)</span> - Generic break, puts next word on
              new line. Should be used to improve readability.
              <br />
              <span style="color: red">[time]</span> - Pauze conversation for specific
              time. Should be used to create timing and tension.
            </p>

            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']"></p>
            <!-- <Description :description="projectBackground"></Description> -->
          </div>
          <div class="columnRight">
            <div class="section">
              <v-img
                src="editor tool 2.png"
                style="width: 50%; margin-left: auto; margin-right: auto"
              ></v-img>
            </div>
            <div class="section"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- second section-->
    <div :data-aos="fade" class="page">
      <div class="">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
              In-game conversations
            </h1>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              In the editor each NPC has its own conversation-slot. This is where we can
              assign the scripatble objects created by the editor tool. Once assigned to
              the editor, a text balloon will appear next to the NPC which will start the
              conversation when clicked.
            </p>
            <!-- <Description :description="projectBackground"></Description> -->
          </div>
          <div class="columnRight">
            <div class="section">
              <video
                class="body-video"
                style="margin-top: 4%"
                :id="'video_milo_editor'"
                loop="true"
                autoplay="true"
                preload="auto"
                muted="muted"
                width="100%"
                height="auto"
              >
                <source src="milo talk.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="section">
              <!-- <pre
                style="color: #d1d1d1; background: #202020"
              >  <span style="color:#e66170; font-weight:bold; ">private</span> IEnumerator TypeText<span style="color:#d2cd86; ">(</span>Text txt<span style="color:#d2cd86; ">,</span> <span style="color:#e66170; font-weight:bold; ">string</span> story<span style="color:#d2cd86; ">)</span>
    <span style="color:#b060b0; ">{</span>
        talking <span style="color:#d2cd86; ">=</span> <span style="color:#e66170; font-weight:bold; ">true</span><span style="color:#b060b0; ">;</span>
        txt<span style="color:#d2cd86; ">.</span>text <span style="color:#d2cd86; ">=</span> <span style="color:#02d045; ">"</span><span style="color:#02d045; ">"</span><span style="color:#b060b0; ">;</span>

        <span style="color:#e66170; font-weight:bold; ">foreach</span> <span style="color:#d2cd86; ">(</span><span style="color:#e66170; font-weight:bold; ">char</span> c <span style="color:#e66170; font-weight:bold; ">in</span> story<span style="color:#d2cd86; ">)</span>
        <span style="color:#b060b0; ">{</span>
            <span style="color:#9999a9; ">//Tell the system to add a pause between two words by adding '/'</span>
            <span style="color:#e66170; font-weight:bold; ">if</span> <span style="color:#d2cd86; ">(</span>c <span style="color:#d2cd86; ">=</span><span style="color:#d2cd86; ">=</span> <span style="color:#00c4c4; ">'/'</span><span style="color:#d2cd86; ">)</span>
            <span style="color:#b060b0; ">{</span>
                <span style="color:#e66170; font-weight:bold; ">yield return</span> <span style="color:#e66170; font-weight:bold; ">new</span> WaitForSeconds<span style="color:#d2cd86; ">(</span><span style="color:#008c00; ">1</span><span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>
                txt<span style="color:#d2cd86; ">.</span>text <span style="color:#d2cd86; ">=</span> <span style="color:#02d045; ">"</span><span style="color:#02d045; ">"</span><span style="color:#b060b0; ">;</span>
            <span style="color:#b060b0; ">}</span>
            <span style="color:#e66170; font-weight:bold; ">else</span>
            <span style="color:#b060b0; ">{</span>
                txt<span style="color:#d2cd86; ">.</span>text <span style="color:#d2cd86; ">+</span><span style="color:#d2cd86; ">=</span> c<span style="color:#b060b0; ">;</span>
            <span style="color:#b060b0; ">}</span>
            <span style="color:#e66170; font-weight:bold; ">yield return</span> <span style="color:#e66170; font-weight:bold; ">new</span> WaitForSeconds<span style="color:#d2cd86; ">(</span><span style="color:#009f00; ">0.05</span><span style="color:#006600; ">f</span> <span style="color:#d2cd86; ">*</span> speedMulitplier<span style="color:#d2cd86; ">)</span><span style="color:#b060b0; ">;</span>
        <span style="color:#b060b0; ">}</span>

        talking <span style="color:#d2cd86; ">=</span> <span style="color:#e66170; font-weight:bold; ">false</span><span style="color:#b060b0; ">;</span>
        <span style="color:#e66170; font-weight:bold; ">yield return</span> <span style="color:#e66170; font-weight:bold; ">null</span><span style="color:#b060b0; ">;</span>
    <span style="color:#b060b0; ">}</span>
</pre> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :data-aos="fade" class="page">
      <div class="">
        <div class="row">
          <div class="columnLeft">
            <!-- <p v-html="code"></p> -->
            <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Movement</h1>
            <p v-bind:class="[isLight ? 'body-text-light' : 'body-text']">
              Due to the semi-organic layout of the satellite, the game requires very
              dynamic movement to match it. because of the way the Robin Raaphorst created
              the art, using colliders was not an option. <br />
              Instead I opted for a bezier curve editor. By doing this it was very easy
              for the artist to create organic and lively paths through the scene.

              <br /><br />
              When the player clicks somewhere in the scene, the system will evaluate the
              curve and find the point closest to the clicked point and the player will
              then interpolate between the current position and the target position.
            </p>
            <!-- <Description :description="projectBackground"></Description> -->
          </div>
          <div class="columnRight">
            <div class="section">
              <video
                class="body-video"
                style="margin-top: 4%"
                :id="'video_milo_editor'"
                loop="true"
                autoplay="true"
                preload="auto"
                muted="muted"
                width="100%"
                height="auto"
              >
                <source src="milo walk.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="section"></div>
          </div>
        </div>
      </div>
    </div>
    <div :data-aos="fade" class="page">
        <video
          class="body-video"
          style="margin-top: 4%"
          :id="'video_milo_editor'"
          loop="true"
          autoplay="true"
          preload="auto"
          muted="muted"
          width="100%"
          height="auto"
        >
          <source src="milo walk editor.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    </div>
    <div :data-aos="fade" class="page" style="height: 100% !important">
        <video
          class="body-video"
          style="margin-top: 4%"
          :id="'milo sanguine.mp4'"
          loop="true"
          autoplay="true"
          preload="auto"
          muted="muted"
          width="100%"
          height="auto"
        >
          <source src="milo sanguine.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    <div :data-aos="fade" class="page" style="height: 100% !important">
      <v-img eager class="pageImg" src="5.png"></v-img>
    </div>
  </div>
</template>

<script>
import VideoBanner from "@/components/VideoBanner";
import ImageBanner from "@/components/Banner";
import projects from "@/JS/Projects.js";
import Metadata from "@/components/Metadata";
import Technologies from "@/components/Technologies";
import Description from "@/components/Description";
// import Codeblock from "@/components/Codeblock"

export default {
  name: "ContourPage",
  components: {
    VideoBanner,
    ImageBanner,
    Metadata,
    Technologies,
    Description,
    // Codeblock
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$forceUpdate();
    this.pagedata = projects[1];
  },
  methods: {},
  computed: {
    isLight() {
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
    fade() {
      if (this.$store.state.mobile) return "";
      return "fade-in";
    },
  },
  data() {
    return {
      model: 0,
      pagedata: { name: "", info: "", description: "", meta: "" },
      target_commands:
        "https://raw.githubusercontent.com/marlonsijnesael/realtime3d/main/ColorCMD.js?token=ADZGI7VHOG62YZFAU4GQ56TAEVC7K",
      projectBackground:
        "M.I.L.O. is the brainchild of illustrator <span style='color:#db162f; font-weight: 400'>Robin Raaphorst</span>. In our first year we worked together on our first game project ever and for that reason we also collaborated during her graduation project. ",
      tableHeaders: [
        "Creating visualisations",
        "Viewing visualisations",
        "Collaboration",
      ],
      tableRows: [
        {
          left: "Difficult to learn and use software",
          center:
            "	3D visualisations can’t be opened easily without a specific application",
          right: "Visualisations in 3D software can’t be shared easily",
        },
        {
          left: "Decently powerful computer required for 3D rendering",
          center: "Static visualizations give a limited view of the visitor experience",
          right: "No central place to give and review feedback on the design",
        },
        {
          left: "Big changes often require the creation of an entirely new visualization",
          center:
            "Some methods are not realistic enough to be presented to certain stakeholders",
          right:
            " be presented to certain stakeholders	Not practical to work with multiple people on one visualisation.",
        },
      ],
    };
  },
};
//   {left: "Creating visualisations", center: "Viewing visualisations", right: ""},
</script>

<style lang="scss">
.body-video {
  border-radius: 12px;
  width: 100%;
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.td-col {
  color: #c0c0c0;
}
.th-col {
}
.problemTable {
  background: transparent;
  padding: 40px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.background-dark {
  background-color: #202020;
}
.background-light {
  background-color: #f3f3f3;
}
.contourPage {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100vw;
  z-index: 1;
  color: white;
  overflow-x: hidden;
}
.background {
}
.content {
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  max-width: 1250px;
}
@media only screen and (max-width: 900px) {
  .page:nth-child(1) {
    margin-top: -500px;
    background-color: red;
    display: none;
  }
  .page {
    width: 100vw;
    padding: 4px;
    margin-bottom: 24px;
    border-radius: 50px;
  }
  .background {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .section {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  .columnLeft {
    padding-bottom: 20px;
    padding-top: 24px;
  }

  .columnRight {
    padding-bottom: 32px;
    padding-top: 0;
  }
}
</style>
