<template>
  <div class="banner">
    <v-img eager class="banner-img" :src="image"></v-img>
  </div>
</template>

<script>
export default {
  name: "ContourPage",
  props: ["image", "title"],
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>


.banner{
  position: relative;
  text-align: center;
  width: 72%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;

  -webkit-box-shadow: 5px 5px 36px 5px rgba(0, 0, 0, 0.42);
  box-shadow: 5px 5px 36px 5px rgba(0, 0, 0, 0.42);
}
@media only screen and (max-width: 900px) {
  .bannner{
    height: 10px;
    background: blue;
    max-width: 100%;
  }
  .banner-img {
    width: 100vw;
    overflow-x: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-right: 10px;
    margin-right: 0;
  }

}
</style>
