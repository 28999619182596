<template>
  <div>
    <div class="videoBanner">
      <div class="video-container">
        <span class="titleBig">{{ title }}</span>

        <video
          class="player"
          :id="'video' + title"
          loop="true"
          autoplay="true"
          preload="auto"
          muted="muted"
        >
          <source :src="video" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoBanner",
  props: ["video", "title"],
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import url("https://use.typekit.net/pvy1gzq.css");
.video-container {
  height: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 80px;
}

#custom-message {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.titleBig {
  font-family: sofia-pro, sans-serif;
  font-weight: 900 !important;
  font-style: normal;
  color: white;
  font-size: 1200%;
  font-weight: bolder;
  // mix-blend-mode: difference;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.player {
  margin-top: 50px;
  position: relative;
  width: 72%;
  max-width: 1400px;
  border-radius: 20px;
  margin-bottom: 25px;
  -webkit-box-shadow: 5px 5px 36px 5px rgba(0, 0, 0, 0.42);
  box-shadow: 5px 5px 36px 5px rgba(0, 0, 0, 0.42);
}
video {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 800px) {
  .video-container {
    height: 100%;
    left: 50%;
    width: 98%;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translateX(-50%);
    position: relative;
    margin-bottom: 80px;
    border-radius: 12px;
    height: 400px;

  }
  .titleBig {
    font-size: 600% !important;
  }
  .videoBanner {
    overflow: hidden;
    margin: 0 0 32px 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .player {
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 12px;
  }
  video {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    max-width: 90%;
    max-height: 90%;
    width: auto;
    overflow: hidden;
    border-radius: 12px;
  }
}
</style>
